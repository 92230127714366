import React, { useEffect, useRef, useState } from 'react';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
import { Button } from 'antd';
import { faGift } from '@fortawesome/pro-duotone-svg-icons';
import moment from 'moment';
import _ from 'lodash';
import { userAtom, loyaltyPointsAtom, notificationsAtom } from '../../../../../atoms/Atoms';
import { H3 } from '../../../../shared-components/typography/Title';
import Subtitle from '../../../../shared-components/typography/Subtitle';
import Description from '../../../../shared-components/typography/Description';
import Panel from '../../../../shared-components/panel/Panel';
import { Spinner } from '../../../../shared-components/Spinner';
import { formatNumber, getRecurrence, getRecurrenceLength } from '../../../../../functions';
import bookingApi from '../../../../../api/bookingApi';
import '../profile.css';

function Subscription() {
  const user = useRecoilValue(userAtom);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalSubs, setTotalSubs] = useState(0);
  const [userSubscriptions, setUserSubscriptions] = useState([]);

  function getUserSubscriptions(page) {
    setLoading(true);
    bookingApi
      .get(`subscriptions/v2/${user?.id}?page=${page}&size=${pageSize}`)
      .then((response) => {
        setLoading(false);
        setTotalSubs(response.data.data.total);
        let tempSubs = [];
        if (page === 0) {
          tempSubs = [...response.data.data.subscriptions];
        } else {
          tempSubs = [...userSubscriptions, ...response.data.data.subscriptions];
        }
        setUserSubscriptions(tempSubs);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
    }

    useEffect(() => {
      if (user) {
        setUserSubscriptions([]);
        setCurrentPage(0);
        getUserSubscriptions(0);
      }
    }, [user]);

  return (
    <div className="flex flex-col flex-1 px-4 mb-4 overflow-auto space-y-4">
      <Panel>
        <H3 text={t('subscriptions')} />
        <div className="mt-4 w-full">
          <div key="reload" className="mr-4 mt-2 text-sm">
            {`${totalSubs > 0 ? `You have ${totalSubs} subscription(s)` : 'You have no active subscriptions'}`}
          </div>
          <Button
            key="reload-btn"
            disabled={loading}
            loading={loading}
            onClick={() => {
              setUserSubscriptions([]);
              setCurrentPage(0);
              getUserSubscriptions(0);
            }}
            loaderColor="bg-white"
            className="bg-green-600 hover:bg-green-800 w-20 mr-2 text-white"
          >
            {t('refresh')}
          </Button>
          <br />
          <br />
          <div key="sub-list">
            {userSubscriptions && _.map(userSubscriptions, (sub, index) => (
            <motion.div 
              key={index}
              id={index}
              className={`${!sub?.isActive ? 'border-red-700' : 'border-white'} mx-4 border rounded-md border-dashed p-4 w-auto mb-4`}
              initial={{
                x: 1000,
                opacity: 0,
                transition: {
                  duration: 0.3,
                  ease: [0.43, 0.13, 0.23, 0.96],
                },
              }}
              animate={{
                x: 0,
                opacity: 1,
                transition: {
                  duration: 0.3,
                  ease: [0.43, 0.13, 0.23, 0.96],
                },
              }}
              exit={{
                x: 1000,
                opacity: 0,
                transition: {
                  duration: 0.3,
                  ease: [0.43, 0.13, 0.23, 0.96],
                },
              }}
            >
              <div className={`${sub.isActive ? 'block' : 'hidden'}`}>
              <div className="text-green-700 font-semibold">{sub.isActive ? 'Active' : 'Expired'}</div>
              <div className="font-semibold">{sub.subscription?.nameEn}</div>
              <div>Description: {sub.subscription?.descriptionEn}</div>
              {sub && sub?.location && (<div>Location: {sub.subscription?.location?.nameEn}</div>)}
              {sub?.isActive && <div>Expires {moment().to(sub.endDate)}</div>}
              <div>Started: {moment(sub.startDate).add(-4, 'hour').format('MMM DD, YYYY hh:mm a')}</div>
              <div>Ends: {moment(sub.endDate).add(-4, 'hour').format('MMM DD, YYYY hh:mm a')}</div>               
              <div className="font-semibold">Benefit(s)</div>
              {sub.subscription?.benefits && _.map(sub.subscription?.benefits, (b, i) => (
                <div key={i} className="mx-4">
                  <div>{`${b?.nameEn} (${b.limit} ${getRecurrence(b.recurrence)})`}</div>
                </div>         
              ))}
               {!sub.allowRenewal && 
                <p className="renew-text">This subscription is not renewable.</p>}
               {sub.allowRenewal && 
                <p className="renewable-text">This subscription can be renewed once it expires.</p>}
              </div>
              <div className={`${!sub.isActive ? 'block' : 'hidden'}`}>
              <div className="text-red-600">{sub.isActive ? 'Active' : 'Expired'}</div>
              <div className="font-semibold">{sub.subscription?.nameEn}</div>
              <div>Description: {sub.subscription?.descriptionEn}</div>
              <div>Location: {sub.subscription?.location?.nameEn}</div> 
              <div>Started: {moment(sub.startDate).add(-4, 'hour').format('MMM DD, YYYY')}</div>
              <div>Ended: {moment(sub.endDate).add(-4, 'hour').format('MMM DD, YYYY')}</div>               
              <div>Benefit(s)</div>
              {sub.subscription?.benefits && _.map(sub.subscription?.benefits, (b, i) => (
                <div key={i} className="mx-4">
                  <div>{`${b?.nameEn} ( ${b.limit} ${getRecurrenceLength(b.recurrence)})`}</div>
                </div>         
              ))}
              {sub.allowRenewal && sub.subscription.isActive && 
              <Button
                key="renew-btn"
                text={t('renew')}
                textColor="text-white"
                disabled={loading}
                loading={loading}
                // onClick={() => {
                //   setSelectedButtonIndex(index);
                //   setPlanSelected(sub);
                //   openDrawer();
                // }}
                loaderColor="bg-white"
                className="renew-btn bg-orange-600 hover:bg-orange-800 w-20 mx-2 p-2"
              />}
                {(!sub.allowRenewal || !sub.subscription.isActive) &&
                <p className="renew-text">This Subscription is not renewable.</p>}
              </div>
            </motion.div>
          ))}
          </div>
          <div className="flex">
            {(currentPage + 1) * pageSize <= totalSubs && (
              <Button
                text={t('load_more')}
                textColor="text-white"
                disabled={loading}
                loading={loading}
                onClick={() => {
                  const newPage = currentPage + 1;
                  setCurrentPage(newPage)
                  getUserSubscriptions(newPage);
                }}
                loaderColor="bg-white"
                className="mx-auto mt-4 bg-customgreen hover:ring-2 ring-offset-2 ring-green-500 text-xl"
              />
            )}
          </div>
        </div>
      </Panel>
    </div>
  )
}

export default Subscription;
